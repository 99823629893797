* {
  padding: 0;
  margin: 0;
  outline: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Tondo";
  src: url("./assets/fonts/Tondo-Bold.woff2") format("woff2"),
    url("./assets/fonts/Tondo-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Tondo";
  src: url("./assets/fonts/Tondo-Regular.woff2") format("woff2"),
    url("./assets/fonts/Tondo-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Tondo", sans-serif;
}
