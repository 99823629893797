.wrapper {
  height: 100vh;
  width: 100vw;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  flex-direction: column;
  transition: background-color 0.6s ease-in-out;
}

.reactcodeinput {
  position: relative;
  margin-bottom: 65px;
}

.code-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  height: 100%;
  position: relative;
  flex: 2;
}

.code-container > h1 {
  max-width: 521px;
  font-size: 24px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 52px;
  margin-top: 30px;
}

.spanbg {
  background-image: url("./assets/images/Csvg.svg");
  width: 100%;
  height: 100%;
  background-size: cover;
  position: fixed;
  animation: kenburns-top 12s ease-in-out alternate-reverse backwards infinite;
}

.container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.artwork-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 0px 20px;
}

.sparks {
  width: 733px;
  height: 823px;
  animation: beat 1s ease-in-out 1s infinite alternate;
}

/* .artwork-container > div {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
} */

.artwork-container > img {
  width: 500px;
  height: 500px;
  position: absolute;
  border-radius: 13px;
  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}

.first-box {
  width: 500px;
  height: 500px;
  /* background-color: rgb(132, 254, 213); */
  border-radius: 13px;
  position: absolute;
  /* top: 50%;
  left: 50%; */
  transform: rotate(-4deg);
  transition: background-color 0.6s ease-in-out;
}

.metadata-container {
  height: 500px;
  /* padding-left: 100px; */
  padding: 0px 20px;
  flex: 1;
}

.metadata-container > h1 {
  font-size: 90px;
  color: rgb(255, 255, 255);
  margin-bottom: 21px;
}

.metadata-container > p {
  font-size: 31px;
  color: rgb(255, 255, 255);
}

.logo-image {
  width: 200px;
  height: auto;
}

@media (max-width: 1479px) {
  .metadata-container > h1 {
    font-size: 70px;
  }
  .sparks {
    width: 533px;
    height: 623px;
  }
  .artwork-container > img {
    width: 400px;
    height: 400px;
  }
  .first-box {
    width: 400px;
    height: 400px;
  }
  .metadata-container > p {
    font-size: 24px;
  }
}

@media (max-width: 767px) {
  .container {
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
  }
  .artwork-container > img {
    width: 330px;
    height: 330px;
  }
  .first-box {
    width: 330px;
    height: 330px;
  }
  .sparks {
    width: 433px;
    height: 523px;
  }
  .metadata-container {
    height: auto;
    padding-left: 0px;
  }
  .metadata-container > h1 {
    font-size: 40px;
  }
  .metadata-container > p {
    font-size: 13px;
  }
  .logo-image {
    margin-top: 50px;
    width: 177px;
  }
}

.kenburns-top {
  -webkit-animation: kenburns-top 1s ease-out reverse backwards infinite;
  animation: kenburns-top 1s ease-out reverse backwards infinite;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-10-13 16:10:21
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation kenburns-top
 * ----------------------------------------
 */
@-webkit-keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}
@keyframes kenburns-top {
  0% {
    -webkit-transform: scale(1) translateY(0);
    transform: scale(1) translateY(0);
    -webkit-transform-origin: 50% 16%;
    transform-origin: 50% 16%;
  }
  100% {
    -webkit-transform: scale(1.25) translateY(-15px);
    transform: scale(1.25) translateY(-15px);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
}

@keyframes bump {
  0% {
    transform: scale(1, 0.995);
  }

  25% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1, 0.995);
  }

  75% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(1, 0.995);
  }
}

@keyframes beat {
  0% {
    transform: translateY(5px) scale(0.999999) rotate(1deg);
  }

  25% {
    transform: translateY(0) scale(1) rotate(0);
  }

  50% {
    transform: translateY(8px) scale(0.98) rotate(1deg);
  }

  75% {
    transform: translateY(0) scale(1) rotate(0);
  }

  100% {
    transform: translateY(3px) scale(0.99) rotate(1deg);
  }
}
